import { configureStore } from '@reduxjs/toolkit'
import checkIn from './check-in'
import checkInGroup from './check-in-group'
import dashboard from './dashboard'

export default configureStore({
  reducer: {
    checkIn,
    checkInGroup,
    dashboard
  },
})