import { v4 as uuid } from 'uuid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Center, Spinner } from '@chakra-ui/react';
import Progress from '../components/progress';
import Question from '../components/question';
import Textarea from './components/textarea';
import Button from '../components/button';
import { setValue } from '../../../store/check-in';
import questions from '../questions';
import save from '../save';
import { fileMlDemoText } from '../../../api/functions'
import { signIn } from '../../../api/firebase';

const key = 'typing.feeling';
const q = questions.feeling;

const CheckinTyping = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [text, setText] = useState()
  const [user, setUser] = useState()
  const checkIn = useSelector(state => state.checkIn.data);
  const { inviteId } = useParams();
  const [loading, setLoading] = useState(false)

  useEffect(() => { 
    const getUser = async () => { 
      const signedUser = await signIn()
      setUser(signedUser.user) 
    };  
 
    getUser(); 
  }, []);

  const answerUpdated = value => {
    setText(value)
    dispatch(
      setValue({
        key: 'typing',
        value,
      })
    );
  };

  const submitText = async () => {
    setLoading(true)
    const mlResultId = uuid()

    await fileMlDemoText({ mlResultId, text, user: user.uid })

    await dispatch(
      setValue({
        key: 'mlResultId',
        value: mlResultId
      })
    );
    
    await save({ ...checkIn, typing: text, mlResultId })
    navigate(`/${inviteId}/summary/${mlResultId}`)
  }

  return (
    <>
      <Progress link={`/${inviteId}/start`} hideProgress={true} />
      <Question header={q.header} text={q.text} />
      <Textarea
        onChange={v => answerUpdated(v.target.value)}
        defaultValue={checkIn[key]}
      />
      {!loading &&
        <Button
          onClick={() => submitText()}
        />
      }
      {loading &&
        <Center>
          <Spinner
            thickness='4px'
            speed='2s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      }
    </>
  );
};

export default CheckinTyping;
