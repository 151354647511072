import mood5 from '../../../assets/rating/mood-5.svg';
import mood4 from '../../../assets/rating/mood-4.svg';
import mood3 from '../../../assets/rating/mood-3.svg';
import mood2 from '../../../assets/rating/mood-2.svg';
import mood1 from '../../../assets/rating/mood-1.svg';

import motivation5 from '../../../assets/rating/motivation-5.svg';
import motivation4 from '../../../assets/rating/motivation-4.svg';
import motivation3 from '../../../assets/rating/motivation-3.svg';
import motivation2 from '../../../assets/rating/motivation-2.svg';
import motivation1 from '../../../assets/rating/motivation-1.svg';

import stress5 from '../../../assets/rating/stress-5.svg';
import stress4 from '../../../assets/rating/stress-4.svg';
import stress3 from '../../../assets/rating/stress-3.svg';
import stress2 from '../../../assets/rating/stress-2.svg';
import stress1 from '../../../assets/rating/stress-1.svg';

import anxiety5 from '../../../assets/rating/anxiety-5.svg';
import anxiety4 from '../../../assets/rating/anxiety-4.svg';
import anxiety3 from '../../../assets/rating/anxiety-3.svg';
import anxiety2 from '../../../assets/rating/anxiety-2.svg';
import anxiety1 from '../../../assets/rating/anxiety-1.svg';

export const moodEmojis = [
  {
    id: 0,
    range: [0, 1, 2],
    emoji: mood1,
  },
  {
    id: 1,
    range: [3, 4],
    emoji: mood2,
  },
  {
    id: 2,
    range: [5, 6],
    emoji: mood3,
  },
  {
    id: 3,
    range: [7, 8],
    emoji: mood4,
  },
  {
    id: 4,
    range: [9, 10],
    emoji: mood5,
  },
];

export const motivationEmojis = [
  {
    id: 0,
    range: [0, 1, 2],
    emoji: motivation1,
  },
  {
    id: 1,
    range: [3, 4],
    emoji: motivation2,
  },
  {
    id: 2,
    range: [5, 6],
    emoji: motivation3,
  },
  {
    id: 3,
    range: [7, 8],
    emoji: motivation4,
  },
  {
    id: 4,
    range: [9, 10],
    emoji: motivation5,
  },
];

export const stressEmojis = [
  {
    id: 0,
    range: [0, 1, 2],
    emoji: stress1,
  },
  {
    id: 1,
    range: [3, 4],
    emoji: stress2,
  },
  {
    id: 2,
    range: [5, 6],
    emoji: stress3,
  },
  {
    id: 3,
    range: [7, 8],
    emoji: stress4,
  },
  {
    id: 4,
    range: [9, 10],
    emoji: stress5,
  },
];

export const anxietyEmojis = [
  {
    id: 0,
    range: [0, 1, 2],
    emoji: anxiety1,
  },
  {
    id: 1,
    range: [3, 4],
    emoji: anxiety2,
  },
  {
    id: 2,
    range: [5, 6],
    emoji: anxiety3,
  },
  {
    id: 3,
    range: [7, 8],
    emoji: anxiety4,
  },
  {
    id: 4,
    range: [9, 10],
    emoji: anxiety5,
  },
];
