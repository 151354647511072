import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlayIcon } from '../../../assets/playIcon.svg';
import { ReactComponent as Delete } from '../../../assets/Delete.svg';
import WaveSurfer from 'wavesurfer.js';
import './wave-player.scss'

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#A0A0A3',
  progressColor: '#000000',
  cursorColor: '#000000',
  barWidth: 1.1,
  barRadius: 0,
  responsive: true,
  height: 44,
  normalize: true,
  partialRender: true,
});

const secondsToTime = (e) => {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');

  return m + ':' + s;
};

const WavePlayer = ({ url, reset }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on('ready', () => {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        const totalTime = wavesurfer.current.getDuration();

        setRemainingTime(secondsToTime(totalTime));

        setVolume(volume);
      }
    });

    return () => wavesurfer.current.destroy();
  }, [url, volume]);

  const handlePlayPause = async () => {
    setPlay(!playing);

    wavesurfer.current.playPause();
  };

  useEffect(() => {
    wavesurfer.current.on('audioprocess', function () {
      if (wavesurfer.current.isPlaying()) {
        var totalTime = wavesurfer.current.getDuration(),
          currentTime = wavesurfer.current.getCurrentTime(),
          remainingTime = totalTime - currentTime;

        setRemainingTime(secondsToTime(remainingTime));
      }
    });
  }, []);

  useEffect(() => {
    if (remainingTime === '00:00') {
      setPlay(false);
    }
  }, [remainingTime]);

  const mobile = window.outerWidth < 600;
  if (!mobile) {
    return (
      <div className="wave-container">
        <div className="waveform-wrapper">
          {playing ? (
            <div className="stop-playing" onClick={handlePlayPause} />
          ) : (
            <PlayIcon onClick={handlePlayPause} />
          )}

          <div id="waveform" ref={waveformRef} />
          <p>{remainingTime}</p>
        </div>
        <Delete style={{
          color: '#000'
        }} onClick={() => reset()} />
      </div>
    );
  } else
    return (
      <div className="wave-container wave-container-column">
        <div className="waveform-wrapper">
          <div id="waveform" ref={waveformRef} />
        </div>

        <div className="wave-actions-style">
          {playing ? (
            <div className="stop-playing" onClick={handlePlayPause} />
          ) : (
            <PlayIcon onClick={handlePlayPause} />
          )}
          <p>{remainingTime}</p>
          <div className="delete-bin-style">
            <Delete onClick={() => reset()} />
          </div>
        </div>
      </div>
    );
};

export default WavePlayer;
