import { Image, Container, Link, useMediaQuery } from '@chakra-ui/react';
import Logo from '../../../assets/group-497.svg';
const Header = () => {
  const [isSmallerThan860] = useMediaQuery('(max-width: 860px)');

  return (
    <Container
      maxWidth={1440}
      pl={isSmallerThan860 ? '20px' : '50px'}
      pt="28px"
      pb="28px"
    >
      <Link href="/">
        <Image height={41} className="image" src={Logo} />
      </Link>
    </Container>
  );
};

export default Header;
