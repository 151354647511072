import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { 
  Heading, 
  Input, 
  Image, 
  Text, 
  Center
} from '@chakra-ui/react';

import cheering from '../../assets/bear-thank-you.png';
import getCheckInGroup from './get-check-in-group';
import Button from '../check-in/components/button';
import './input.scss'

const domain =
  process.env.REACT_APP_TYPE === 'staging'
    ? 'earkick-check-in.web.app'
    : 'checkin.earkick.com';

const ThankYou = () => {
  const checkInGroup = useSelector(state => state.checkInGroup.data);
  const { inviteId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await getCheckInGroup(inviteId, checkInGroup, dispatch);
    })();
  }, [inviteId, checkInGroup, dispatch]);

  const [value, setValue] = useState('');

  useEffect(() => {
    const getLink = () => {
      return `https://${domain}/dashboard/${inviteId}`;
    };

    setValue(getLink());
  }, [inviteId]);
  return (
    <>
      <Heading
        fontWeight={700}
        fontSize="30px"
        lineHeight="40px"
        textAlign="center"
        color="#000000"
        mb="40px"
      >
        {checkInGroup.name} <span style={{ fontWeight: 400 }}>says</span>
      </Heading>
      <Center>
        <Image width="136px" marginBottom="14px" src={cheering} />
      </Center>
      <Text
        fontWeight={700}
        fontSize="30px"
        lineHeight="45px"
        textAlign="center"
        color="black"
        mb="80px"
      >
        Thank you!!
      </Text>

      <Text
        fontWeight={700}
        fontSize="20px"
        lineHeight="30px"
        textAlign="center"
        color="black"
      >
        See screening results
      </Text>

      <Center>
        <Input
          value={value}
          readOnly={true}
          className="earkick-input"
        />
      </Center>

      <Center>
        <Button
          minHeight="40px"
          height="40px"
          onClick={() =>
            (window.location.href = value)
          }
          textTransform="capitalize"
          text="See results"
          width="263px"
          minWidth="263px"
        />
      </Center>
    </>
  );
};

export default ThankYou;
