import { Route, Routes } from 'react-router-dom'
import VoiceStart from '../../containers/check-in/voice'

const CheckInVoiceRoutes = () => (
  <Routes>
    <Route
      path=''
      element={(
        <VoiceStart />
      )}
    />
  </Routes>
)

export default CheckInVoiceRoutes