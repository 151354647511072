import { Box, Center, Container, Image, Text } from '@chakra-ui/react'
import './features.scss'
import feature1 from '../../../assets/home/feature-1.png'
import feature2 from '../../../assets/home/feature-2.png'
import feature3 from '../../../assets/home/feature-3.png'

const FeaturesComponent = () => {
  return (
    <Container className="features">
      <Center className="feature">
        <Image src={feature1} marginRight="68px" />
        <Box>
          <Text className="heading">
            Effective and Efficient
          </Text>  
          <Text>
            Assess your company’s mental readiness in no time. 
            10 seconds of audio is enough to assess mental readiness, mood, motivation, and stress.
          </Text>  
        </Box>
      </Center> 

      <Center className="feature">
        <Box>
          <Text className="heading">
            Clarity and Insight 
          </Text>  
          <Text>
          Get a clear understanding where your company stands and whether there is a need to act on your side.
          </Text>  
        </Box>
        <Image src={feature2} marginLeft="27px" />
      </Center>

      <Center className="feature">
        <Image src={feature3} marginRight="55px" />
        <Box>
          <Text className="heading">
            100% Anonymous
          </Text>  
          <Text>
            Assess your company's mental readiness in no time. 
            Unlike other tools, Earkick does not require any personal information from any participant. No registration required and everyone gets access to the results.
          </Text>  
        </Box>
      </Center> 
    </Container>
  )
}

export default FeaturesComponent