import {
  Box,
  Center,
  Container,
  Link,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { socialIcons, footerLinks } from './footerData';
import './footer.scss';

const Footer = () => {
  const [isSmallerThan860] = useMediaQuery('(max-width: 860px)');

  return (
    <Box
      bg={useColorModeValue('#312A77', 'initial')}
      color={useColorModeValue('white', 'initial')}
      className="footer"
      p="21px"
    >
      <Container
        maxWidth="unset"
        margin="0 auto"
        flexDirection={isSmallerThan860 ? 'column' : 'row'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={0}
      >
        <Box
          w="auto"
          mb={isSmallerThan860 ? '33px' : 0}
          className="footer__link"
        >
          {footerLinks.map(({ href, name }) => {
            return (
              <Link pl={27} pr={27} key={name} href={href} className="links">
                {name}
              </Link>
            );
          })}
        </Box>

        <Box
          w="auto"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          className="footer__social"
        >
          {socialIcons.map(({ label, href, icon }) => {
            return (
              <Link
                key={label}
                label={label}
                target="_blank"
                href={href}
                mr={27}
                ml={27}
              >
                {icon}
              </Link>
            );
          })}
        </Box>
      </Container>

      <Center mt="28px">
        <Text className="footer__copy">©2022 Earkick Inc.</Text>
      </Center>
    </Box>
  );
};

export default Footer;
