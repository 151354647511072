import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

const checkInPatientFinished = httpsCallable(functions, 'checkInPatientFinished')
const checkInSave = httpsCallable(functions, 'checkInSave');
const fileMlDemoText = httpsCallable(functions, 'fileMlDemoText');
const groupCheckInCreate = httpsCallable(functions, 'groupCheckInCreate');
const groupCheckInGet = httpsCallable(functions, 'groupCheckInGet');
const groupCheckInSummary = httpsCallable(functions, 'groupCheckInSummary');

export {
  checkInSave,
  checkInPatientFinished,
  fileMlDemoText,
  groupCheckInCreate,
  groupCheckInGet,
  groupCheckInSummary,
};
