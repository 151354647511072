import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import Routing from './routes'
import ScrollToTop from './components/scroll-to-top'
import './assets/index.css'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routing />
    </BrowserRouter>
  );
}

export default App;
