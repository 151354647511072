import { useEffect, useState } from 'react';

const RecordState = {
  INIT: 'init',
  DISABLE: 'disable',
  START: 'start',
  PAUSE: 'pause',
  STOP: 'stop',
  NONE: 'none'
}

const recordAudio = ({
  onStop,
}) => {
  return new Promise(resolve => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks = [];

        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          const play = () => {
            audio.play();
          };

          stream.getTracks().forEach(function(track) {
            if(track.state !== 'inactive') {
              track.stop();
            }
          });

          onStop({ blob: audioBlob, url: audioUrl, play });
        });

        const start = () => {
          mediaRecorder.start();
        };

        const stop = () => {
          mediaRecorder.stop();
        };

        const disable = () => {
          const tracks = stream.getTracks()
          tracks.forEach(track => {
            stream.removeTrack(track)
          })
        };

        resolve({ disable, start, stop });
      });
  });
};

const AudioRecorder = ({ state, onStop, onStart }) => {
  const [recorder, setRecorder] = useState()

  useEffect(() => {
    const x = async () => {
      if(state === RecordState.INIT) {
        const r = await recordAudio({ onStop })
        setRecorder(r) 
      } else if(state === RecordState.START) {
        recorder.start()
        onStart()
      } else if(state === RecordState.STOP) {
        await recorder.stop()
      } else if(state === RecordState.DISABLE) {
        recorder.disable()
      }
    }

    x()

  }, [state])

  return (
    <div className='audio-react-recorder'>
      Audio Recorder
    </div>
  )
}

export {
  AudioRecorder,
  RecordState
}