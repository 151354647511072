import { Outlet } from 'react-router';
import { Container } from '@chakra-ui/react';

const C = () => {
  // const checkIn = useSelector(state => state.checkIn.data);

  return (
    <Container p={0} mb={10} maxWidth="390px">
      <Outlet />
    </Container>
  );
};

export default C;
