import React, { memo, Suspense } from 'react'
import {
  Navigate,
  Routes,
  Route,
  useParams
} from 'react-router-dom'
import CheckInLayout from '../components/layouts/check-in'
import DashboardLayout from '../components/layouts/manager'

import CheckInRoutes from './check-in'
import DashboardRoutes from './dashboard'

const RedirectToDashboard = () => {
  const { inviteId } = useParams()
  return (<Navigate replace to={`/dashboard/${inviteId}`}></Navigate>)
}

const Routing = memo(() => {
  return (
    <Suspense className='main-container' fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route path="/manager/:inviteId" element={<RedirectToDashboard />} />
          <Route path="/dashboard/*" element={<DashboardRoutes />}></Route>
        </Route>
        <Route path="/" element={<Navigate to="/dashboard"></Navigate>}></Route>
        <Route element={<CheckInLayout />}>
          <Route path="/:inviteId/*" element={<CheckInRoutes />}></Route>
        </Route>
        
        <Route path="*" element={( <>Not found</> )}></Route>
      </Routes>
    </Suspense>
)})

Routing.displayName = 'Routing'

export default Routing
