import { Box, Image, Text } from '@chakra-ui/react';
import pointerProgressBackground from '../../../assets/pointer-chart.png';
import pointerPart from '../../../assets/pointer-1.svg';
import circle from '../../../assets/pointer-center.svg';

const PointerProgress = ({ percentage, type }) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box position="relative">
        <Image width="130px" alt="test" src={pointerProgressBackground} />

        <Box
          position="absolute"
          top="34.9px"
          left="8px"
          transform={`rotate(${percentage * 1.8 + 5 || '0'}deg)`}
          transformOrigin="85% 47%"
        >
          <Image alt="test" src={pointerPart} />
          <Image
            width="4px"
            alt="test"
            src={circle}
            style={{
              position: 'absolute',
              right: '7px',
              bottom: 'calc(55% - 4px)',
            }}
          />
        </Box>
      </Box>
      <Text
        fontWeight={700}
        fontSize="12px"
        lineHeight="18px"
        color="black"
        textAlign="center"
        marginTop="12px"
      >
        {type}
      </Text>
    </Box>
  );
};

export default PointerProgress;
