import { subscribeOne, update } from './db'

const collectionName = 'ml-results'

const mlResultUpdate = async (id, data) => {
  await update(collectionName, id, data)
}

const mlResultSubscribe = (id, mutationCallback) => subscribeOne(collectionName, id, mutationCallback)

export {
  mlResultUpdate,
  mlResultSubscribe
}
