import { checkInSave } from '../../api/functions'

const save = async data => {
  if(!data.inviteId) {
    throw Error('No inviteId')
  }

  if(!data.inviteId) {
    throw Error('No id')
  }

  await checkInSave(data)
}

export default save