import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Box, Text, Image } from '@chakra-ui/react';

const Chart = data => {
  const [scale, setScale] = useState(1)
  const stats = data.data;

  useEffect(() => {
    const max = _.max(stats)
    if(max > 10) {
      setScale( 10 / max)
    } 
  }, [stats])

  return (
    <Box
      width="356px"
      height="331.26px"
      border="3px solid #C8C8C8"
      borderRadius="15px"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Text
        fontWeight={700}
        fontSize="17px"
        lineHeight="25px"
        color="#999999"
        textAlign="center"
        marginBottom="40px"
      >
        {data.title}
      </Text>

      <div className="chart">
        <div className="chart__container top">
          {stats.map((stat, index) => {
            return (
              <div
                style={{
                  height: (stat * scale + 0.1) * 10 + '%',
                  background: data.color,
                }}
                key={`${index}-purple`}
                className="chart__container--inner purple"
              />
            );
          })}
        </div>
        <div className="divider" />

        <div className="chart__container">
          {data.emojis.map((emoji, index) => {
            return (
              <div
                key={`${index}-emoji`}
                className="chart__container--inner emoji"
              >
                <Image width="24px" src={emoji.emoji} alt="emoji" />
              </div>
            );
          })}
        </div>

        <div className="chart__container">
          {stats.map((number, index) => {
            return (
              <div key={`${index}-data`} className="chart__container--inner">
                {number}
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default Chart;
