import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Progress from '../components/progress';
import Question from '../components/question';
import Button from '../components/button';
import { Box, Heading, Image, Text, Center, Container, Spinner } from '@chakra-ui/react';
import head from '../../../assets/head-border.svg';
import Video from './video';
import { setValue } from '../../../store/check-in';
import questions from '../questions';
import StopIcon from '../../../assets/stop-icon.svg'
import VideoBlackIcon from '../../../assets/video-black-icon.svg'
import DeleteModal from '../components/delete-modal'
import { useEffect } from 'react';

const keys = [
  '',
  'video.feeling',
  'video.anxious',
  'video.stress',
  'video.symptoms',
  'video.else',
];

const CheckinVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [key, setKey] = useState(keys[step]);
  const checkIn = useSelector(state => state.checkIn.data);
  const [videoCommand, setVideoCommand] = useState('init');
  const [nextDisabled, setNextDisabled] = useState(false)
  const [videoLength, setVideoLength] = useState("00:00");
  const [timerInterval, setTimerInterval] = useState()
  const [confirmationModal, showConfirmationModal] = useState(false);
  const [mlResultId, setMlResultId] = useState()

  const { inviteId } = useParams();
  const videoId = `check-ins.${inviteId}.${checkIn.id}.${key}`;

  const videoUploaded = () => {
    return dispatch(
      setValue({
        key: 'video',
        value: videoId,
      })
    );
  };

  const mlResultIdSet = async (id) => {
    setMlResultId(id)
    setNextDisabled(false)
    return dispatch(
      setValue({
        key: 'mlResultId',
        value: id
      })
    );
  }

  const nextClicked = () => {
    setStep(step + 1);
    setKey(keys[step + 1]);
    console.log(`Next clicked now ${step} ${videoCommand}`)
    if (videoCommand === 'init') {
      setNextDisabled(true)
      setTimeout(() => {
      setNextDisabled(false)
      setVideoCommand('start');
      }, 2000);
    } else if (videoCommand === 'start') {
      setVideoCommand('stop');
      setTimeout(() => {
        setVideoCommand('preview');
      }, 250);
    } else if (videoCommand === 'preview') {
      console.log('uploading finally?')
      setNextDisabled(true)
      setVideoCommand('upload');
    }
  };

  return (
    <>
      <Progress
        link={step < 3 ? `/${inviteId}/start` : ''}
        hideProgress={true}
      />
      {step === 0 && (
        <Center display="flex" flexDirection="column">
          <Heading
            fontFamily="Poppins"
            fontWeight={700}
            fontSize="20px"
            lineHeight="30px"
            color="#000000"
            textAlign="center"
          >
            Align your face!
          </Heading>
          <Text
            fontFamily="Poppins"
            fontWeight={400}
            fontSize="20px"
            lineHeight="30px"
            color="#000000"
            textAlign="center"
            mb="60px"
          >
            Try to place your eyes <br /> behind the sunglasses
          </Text>

          <Container p={0} m={0} position="relative" maxWidth="334px">
            <Image
              width={333}
              height={332}
              src={head}
              borderRadius={'15px'}
              position="absolute"
              top="2px"
              left={0}
            />
            <Video
              name={key}
              id={videoId}
              videoUploaded={videoUploaded}
              command={videoCommand}
            />
          </Container>
        </Center>
      )}
      {step > 0 && (
        <>
          {step !== 0 && step < 2 && 
            <Question
              header={questions[key.replace('video.', '')].header}
              text={questions[key.replace('video.', '')].text}
            />
          }
          <Video
            name={key}
            id={videoId}
            videoUploaded={videoUploaded}
            command={videoCommand}
            showPlayer={step >= 2}
            setVideoLength={setVideoLength}
            videoLength={videoLength}
            timerInterval={timerInterval}
            setTimerInterval={setTimerInterval}  
            onDelete={showConfirmationModal}
            mlResultIdSet={mlResultIdSet}       
          />
        </>
      )}
      <Center>
        {step === 0 &&
          <Button
            onClick={() => nextClicked()}
            width="350px"
            minWidth="350px"
            text={'Start'}
            disabled={nextDisabled}
          />
        }
        {step === 1 &&
          <>
            <Center>
              <Container>

              {!nextDisabled &&
                <Box
                style={{
                  width: '70px',
                  height: '70px',
                  background: `#EC6F47 url(${StopIcon}) center center no-repeat`,
                  border: '4px solid #ddd',
                  borderRadius: '50px',
                  cursor: 'pointer'
                }}
                aria-label='Play'
                size='lg'
                onClick={() => nextClicked()}
                ></Box>
              }
              {nextDisabled &&
                <Box
                style={{
                  width: '70px',
                  height: '70px',
                  background: `#aaa url(${StopIcon}) center center no-repeat`,
                  border: '4px solid #ddd',
                  borderRadius: '50px',
                  cursor: 'pointer'
                }}
                aria-label='Play'
                size='lg'
                ></Box>
              }
              </Container>
            </Center>

            <Center>
              <Container>
                <Box style={{
                  width: '70px',
                  height: '40px',
                  fontWeight: 700,
                  fontSize: '24px',
                  color: '#000',
                  textAlign: 'center'
                }}>{videoLength}</Box>
              </Container>
            </Center>
          </>
        }
        {step === 2 &&
          <Button
            onClick={() => nextClicked()}
            width="350px"
            minWidth="350px"
            text={'Next'}
            disabled={nextDisabled}
          />
        }
        {step === 3 &&
          <Spinner
            thickness='4px'
            speed='2s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        }
      </Center>

      <DeleteModal 
        isOpen={confirmationModal}
        onClose={() => showConfirmationModal(false)}
        onDelete={() => navigate(`/${inviteId}/start`)}
        icon={VideoBlackIcon}
        subject={"video"}
      />
    </>
  );
};

export default CheckinVideo;
