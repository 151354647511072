import { useDispatch, useSelector } from 'react-redux';
import { Box, Heading, Container, Image } from '@chakra-ui/react';
import { setSummaryValue } from '../../../store/check-in';
import { useEffect } from 'react';

const capitalize = value => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const Rating = ({ name, icons, initialValue }) => {
  const key = name;
  const dispatch = useDispatch();
  const checkIn = useSelector(state => state.checkIn.data);

  const selected = value => {
    dispatch(
      setSummaryValue({
        key,
        value,
      })
    );
  };

  useEffect(() => {
    selected(initialValue)
  }, [initialValue])

  return (
    <Container p={0} mb="51px">
      <Heading
        fontSize="17px"
        lineHeight="26px"
        textAlign="center"
        color="#999999"
        fontFamily="Poppins"
      >
        {capitalize(name)}
      </Heading>
      <Container
        p={0}
        m={0}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        {icons.map((icon, i) => (
          <Box
            key={i + 1}
            margin="8px"
            fontSize="30px"
            width="36px"
            height="36px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            textAlign="center"
            backgroundColor={checkIn.summary[key] === i ? '#5F55C3' : 'transparent'}
            borderRadius="50%"
            mr="13px"
            ml="13px"
            onClick={() => selected(i)}
          >
            <Image width="80%" src={icon} alt="Mood" />
          </Box>
        ))}
      </Container>
    </Container>
  );
};

export default Rating;
