import { Box, Container, Text, Image } from '@chakra-ui/react';
import radialProgress from '../../../assets/chart-background.png';
import radialProgressStatus from '../../../assets/radial-progress-status.svg';

const radialProgressBar = ({ progress }) => {
  return (
    <Container>
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="row"
        width="146px"
        height="76px"
        maxWidth="100%"
        justifyContent="center"
        position="relative"
        overflow="hidden"
      >
        <Box
          width="146px"
          height="146px"
          position="absolute"
          top="-34px"
          left="0px"
          zIndex="-1"
          style={{
            backgroundColor: '#dcdcdc'
          }}
        >
        </Box>
        <Image
          src={radialProgress}
          alt="progress background"
          width="146px"
          position="absolute"
          top="-34px"
          left="0px"
        />

        <Text
          position="absolute"
          top="35px"
          fontWeight={700}
          fontSize="28px"
          lineHeight="33px"
          bottom="0"
        >
          {progress}
        </Text>

        <Image
          transform={`rotate(${progress * 1.8 + 180}deg)`}
          transformOrigin="50% 100%"
          src={radialProgressStatus}
          alt="progress track"
          position="absolute"
          zIndex="-1"
        />
      </Box>

      <Text
        textAlign="center"
        fontWeight={700}
        fontSize="12px"
        lineHeight="18px"
        marginTop="10px"
      >
        Readiness Score
      </Text>
    </Container>
  );
};

export default radialProgressBar;
