import { useEffect, useState } from 'react';
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useClipboard,
  Center,
} from '@chakra-ui/react';
import Input from '../check-in/components/input';
import Button from '../check-in/components/button';

const domain =
  process.env.REACT_APP_TYPE === 'staging'
    ? 'earkick-check-in.web.app'
    : 'checkin.earkick.com';

const InviteModal = ({ inviteId, isOpen, onClose }) => {
  const [value, setValue] = useState('');
  const { hasCopied, onCopy } = useClipboard(value);

  useEffect(() => {
    const getLink = () => {
      return `https://${domain}/${inviteId}`;
    };

    setValue(getLink());
  }, [inviteId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      isCentered={true}
      className="modal-container"
    >
      <ModalOverlay background="rgba(0, 0, 0, 0.45);" />
      <ModalContent p={8} m={2}>
        <ModalHeader textAlign="center">
          <Heading
            fontFamily="Poppins"
            fontWeight={700}
            lineHeight="30px"
            textAlign="center"
            fontSize={20}
            color="#000000"
          >
            Invite Link
          </Heading>
          <Heading
            fontFamily="Poppins"
            fontWeight={700}
            lineHeight="30px"
            textAlign="center"
            fontSize={20}
            color="#C8C8C8"
          >
            Share this with the participants
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Center>
            <Input
              value={value}
              readOnly={true}
              width={{ base: '100%', sm: '356px' }}
            />
          </Center>
        </ModalBody>

        <ModalFooter>
          <Center width="100%" mt="18px">
            <Button
              minWidth="219px"
              onClick={onCopy}
              text={hasCopied ? 'Copied' : 'Copy'}
              small
            />
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteModal;
