import { createSlice } from '@reduxjs/toolkit';

export const checkIn = createSlice({
  name: 'checkIn',
  initialState: {
    data: {
      summary: {
        mood: null,
        motivation: null,
        stress: null,
        anxiety: null,
      },
      email: '',
    },
  },
  reducers: {
    set: (state, action) => {
      state.data = action.payload;
    },
    setValue: (state, { payload }) => {
      state.data[payload.key] = payload.value;
    },
    setSummaryValue: (state, { payload }) => {
      state.data.summary[payload.key] = payload.value;
    },
  },
});

export const { set, setSummaryValue, setValue } = checkIn.actions;

export default checkIn.reducer;
