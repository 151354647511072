import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import WavePlayer from './wavePlayer';
import Progress from '../components/progress';
import Question from '../components/question';
import { Box, Center, Heading, Spinner, Text } from '@chakra-ui/react';

import questions from '../questions';
import save from '../save';
import StopIcon from '../../../assets/stop-icon.svg'
import VoiceIcon from '../../../assets/voice-icon.svg'
import VoiceBlackIcon from '../../../assets/voice-black-icon.svg'
import { AudioRecorder, RecordState } from '../components/audioRecorder'
import { milisToTime } from '../helpers';
import DeleteModal from '../components/delete-modal'
import { submitFile } from '../../../api/submit-file'
import { setValue } from '../../../store/check-in';

const CheckinVoice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const checkIn = useSelector(state => state.checkIn.data);
  const [loading, setLoading] = useState(false)
  const [voiceLength, setVoiceLength] = useState("00:00");
  const [timerInterval, setTimerInterval] = useState()
  const [recordState, setRecordState] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [confirmationModal, showConfirmationModal] = useState(false);
  const [startTimer, setStartTimer] = useState(false)

  const { inviteId } = useParams();
  const voiceId = `check-ins.${inviteId}.${checkIn.id}`;

  const countRef = useRef(null);
  const voiceUploaded = async () => {
    dispatch(
      setValue({
        key: 'audio',
        value: voiceId,
      })
    );
  };

  useEffect(() => {
    setRecordState(RecordState.INIT);
  }, [])

  const startRecord = async () => {
    let play = false;
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => (play = true))
      .catch(() => (play = false));

    if (!play) return null;
    setTimeout(() => {
      setRecordState(RecordState.START);

    }, 300);
  };

  const pauseRecord = () => {
    clearInterval(timerInterval)
    setRecordState(RecordState.STOP);
  };

  const onStartRecord = () => {
    const startNow = new Date()
    
    const intervalId = setInterval(() => {
      let startTime;
      if(startTimer === false) {
        setStartTimer(startNow)
        startTime = startNow.getTime()
      } else {
        startTime = startTimer.getTime()
      }

      const timeNow = new Date()
      setVoiceLength(milisToTime(timeNow.getTime() - startTime))
    }, 100)
    setTimerInterval(intervalId)
  }

  const onStopRecord = (audioData) => {
    clearInterval(timerInterval)
    setAudioData(audioData);
    clearInterval(countRef.current);
    voiceUploaded()
  };

  const reset = () => {
    setRecordState(null);
    showConfirmationModal(false);
    navigate(`/${inviteId}/start`)
  };

  const submit = async () => {
    setLoading(true)
    const resultId = await submitFile({ url: audioData?.url })
    await dispatch(
      setValue({
        key: 'mlResultId',
        value: resultId
      })
    );
    setRecordState('disable');
    await save({ ...checkIn, mlResultId: resultId })
    navigate(`/${inviteId}/summary/${resultId}`)
  }

  return (
    <>
      <Progress
        link={`/${inviteId}/start`}
        hideProgress={true}
      />

      <>
        {!audioData?.url && 
          <Question
            header={questions['feeling'].header}
            text={questions['feeling'].text}
          />
        }
        <div style={{ display: 'none' }}>
          <AudioRecorder 
            setVoiceLength={setVoiceLength}
            voiceLength={voiceLength}
            state={recordState} 
            onStop={onStopRecord}   
            onStart={onStartRecord}  
          />
        </div>

        {(recordState === RecordState.STOP || recordState === RecordState.DISABLE) && audioData?.url && (
          <div className="audio-container">
            <Heading
              fontSize="24px"
              lineHeight="36px"
              color="#444444"
              fontWeight={700}
              marginTop="16px"
              marginBottom="46px"
            >Confirm your recording</Heading>
            <WavePlayer
              url={audioData?.url}
              reset={() => showConfirmationModal(true)}
            />
            {!loading &&
              <button
                onClick={async () => submit()}
              >
                CONFIRM
              </button>
            }
            {loading &&
              <Center
                style={{ marginTop: '50px' }}
              >
                <Spinner
                  thickness='4px'
                  speed='2s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
              </Center>
            }
          </div>
        )}
      </>

      <Center>
        {(recordState === null || recordState === RecordState.INIT)  &&
          <Box
            style={{
              paddingTop: '37px',
              width: '182px',
              height: '167px',
              backgroundColor: '#ddd',
              borderRadius: '10px'
            }}
          >
            <Center>
              <Box
                style={{
                width: '64px',
                height: '64px',
                background: `#000000 url(${VoiceIcon}) center center no-repeat`,
                border: '6px solid #aaa',
                borderRadius: '50px',
                cursor: 'pointer',
                marginBottom: '15px'
              }}
              aria-label='Play'
              size='lg'
              onClick={startRecord}
              ></Box>
            </Center>
            <Center>
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: '17px'
                }}
              >Tap to start</Text>
            </Center>
          </Box>
        }
        {recordState === RecordState.START &&
          <Box
            style={{
              paddingTop: '37px',
              width: '182px',
              height: '167px',
              backgroundColor: '#ddd',
              borderRadius: '10px'
            }}
          >
            <Center>
              <Box
                style={{
                width: '64px',
                height: '64px',
                background: `#EC6F47 url(${StopIcon}) center center no-repeat`,
                border: '6px solid #aaa',
                borderRadius: '50px',
                cursor: 'pointer',
                marginBottom: '15px'
              }}
              aria-label='Play'
              size='lg'
              onClick={pauseRecord}
              ></Box>
            </Center>
            <Center>
              <Text>{voiceLength}</Text>
            </Center>
          </Box>
        }
      </Center>

      <DeleteModal 
        isOpen={confirmationModal}
        onClose={() => showConfirmationModal(false)}
        onDelete={() => reset()}
        icon={VoiceBlackIcon}
        subject={"voice"}
      />

    </>
  );
};

export default CheckinVoice;
