import { initializeApp } from 'firebase/app'
import { getFunctions } from "firebase/functions";
import { getFirestore } from 'firebase/firestore'
import { getAuth, signInAnonymously } from "firebase/auth"
import { getStorage } from "firebase/storage"

import productionConfig from './production_config.json' 
import stagingConfig from './staging_config.json' 

const config = process.env.REACT_APP_TYPE === 'staging' ? stagingConfig : productionConfig

const app = initializeApp(config)
const functions = getFunctions()
const db = getFirestore(app)

const signIn = async () => {
    const auth = getAuth()
    return await signInAnonymously(auth)
}

const storage = getStorage();

export {
    db,
    functions,
    signIn,
    storage,
  }