import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'
import { Box, Center, Container, Heading } from '@chakra-ui/react';
import { setValue } from '../../store/check-in';
import save from './save';
import Input from '../check-in/components/input';
import Button from '../check-in/components/button';
import { checkInPatientFinished } from '../../api/functions';
import ThankYou from './thank-you'

const key = 'email';

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is a required field'),
});

const CheckInStart = () => {
  const dispatch = useDispatch();
  const checkIn = useSelector(state => state.checkIn.data);
  const [linkSent, setLinkSent] = useState(false)

  const emailUpdated = async value => {
    dispatch(
      setValue({
        key,
        value,
      })
    );
 
    save({
      ...checkIn,
      email: value
    });

    const response = await checkInPatientFinished({ 
      email: value,
      groupCheckInId: checkIn.inviteId
    })
    console.log(response)
  };

  return (
    <Container pt="124px" pb="20px">
      <Box pb="100px">
        <ThankYou></ThankYou>
      </Box>
      {linkSent &&
        <Heading
          fontFamily="Poppins"
          fontSize="24px"
          fontWeight={700}
          lineHeight="30px"
          textAlign="center"
          color="#a0a0a0"
        >
          Link sent!
        </Heading>
      }
      {!linkSent &&
        <>
          <Heading
            fontFamily="Poppins"
            fontSize="20px"
            fontWeight={700}
            lineHeight="30px"
            textAlign="center"
            color="#000000"
            marginBottom="26px"
          >
            Send me the link
          </Heading>

          <Formik
            validationSchema={emailSchema}
            initialValues={{ email: checkIn[key] || '' }}
            onSubmit={async values => {
              await emailUpdated(values.email);
              setLinkSent(true)
            }}
          >
            {props => (
              <Form noValidate>
                <Field name="email">
                  {({ form }) => (
                    <Input
                      value={form.values.email}
                      placeholder="Email"
                      isRequired={true}
                      type="email"
                      onChange={e => form.setFieldValue('email', e.target.value)}
                      error={form.errors.email}
                    />
                  )}
                </Field>
                <Center mt="26px" mb="26px">
                  <Button
                    textTransform="capitalize"
                    minHeight="40px"
                    height="40px"
                    disabled={props.errors.email || props.values.email === ''}
                    type="submit"
                    isLoading={props.isSubmitting}
                    text="Send link"
                  />
                </Center>
              </Form>
            )}
          </Formik>
        </>
      }
    </Container>
  );
};

export default CheckInStart;
