import {
  FaInstagram,
  FaFacebook,
  FaReddit,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import tikTokIcon from '../../../assets/tikTokIcon.svg';
import { Image } from '@chakra-ui/react';

export const socialIcons = [
  {
    id: 1,
    label: 'Twitter',
    icon: <FaTwitter />,
    href: 'https://twitter.com/earkickapp',
  },
  {
    id: 2,
    label: 'Instagram',
    icon: <FaInstagram />,
    href: 'https://www.instagram.com/earkick/',
  },
  {
    id: 3,
    label: 'Facebook',
    icon: <FaFacebook />,
    href: 'https://www.facebook.com/earkick',
  },
  {
    id: 4,
    label: 'Reddit',
    icon: <FaReddit />,
    href: 'https://www.reddit.com/user/Earkick/',
  },
  {
    id: 5,
    label: 'TikTok',
    icon: <Image src={tikTokIcon} />,
    href: 'https://www.tiktok.com/@earkick',
  },
  {
    id: 6,
    label: 'Youtube',
    icon: <FaYoutube />,
    href: 'https://www.youtube.com/channel/UCjqXTMgHNd0LlncJBGeV8cA',
  },
];

export const footerLinks = [
  {
    id: 1,
    name: 'About us',
    href: 'https://earkick.com/about',
  },
  {
    id: 2,
    name: 'Blog',
    href: 'https://blog.earkick.com/',
  },
  {
    id: 3,
    name: 'Privacy',
    href: 'https://earkick.com/privacy-policy',
  },
];
