import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import Index from '../../containers/check-in/index'
import Start from '../../containers/check-in/start'
import Email from '../../containers/check-in/email'
import Summary from '../../containers/check-in/summary'
import CheckInTypingLayout from '../../containers/check-in/typing/layout'

import NotFound from '../../containers/check-in/not-found'
import CheckInTypingRoutes from './typing'
import CheckInVoiceRoutes from './voice'
import CheckInVideoRoutes from './video'

const AccountRoutes = memo(() => (
  <Routes>
    <Route
      path='/'
      element={(
        <Index />
    )}
    />
    <Route
      path='start'
      element={(
        <Start />
      )}
    />
    <Route element={<CheckInTypingLayout />}>
      <Route path="typing/*" element={<CheckInTypingRoutes />}></Route>
    </Route>

    <Route element={<CheckInTypingLayout />}>
      <Route path="voice/*" element={<CheckInVoiceRoutes />}></Route>
    </Route>

    <Route element={<CheckInTypingLayout />}>
      <Route path="video/*" element={<CheckInVideoRoutes />}></Route>
    </Route>

    <Route
      path='summary/:resultId'
      element={(
        <Summary />
      )}
    />
    <Route
      path='email'
      element={(
        <Email />
      )}
    />
    <Route
      path='*'
      element={(
        <NotFound />
      )}
    />

  </Routes>
  
 ))

AccountRoutes.displayName = 'AccountRoutes'

export default AccountRoutes