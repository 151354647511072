import { useEffect, useState } from 'react';
import {  Center, Container, Heading, Box, Spinner, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/button';
import Rating from './rating';
import { useSelector } from 'react-redux';
import React from 'react';
import save from '../save';
import { mlResultSubscribe } from '../../../api/ml-results';
import { signIn } from '../../../api/firebase';
import {
  moodIcons,
  motivationIcons,
  stressIcons,
  anxietyIcons
} from './icons';

const SummaryComponent = () => {
  const navigate = useNavigate()
  const { inviteId, resultId } = useParams();
  const checkIn = useSelector(state => state.checkIn.data);
  const [user, setUser] = useState();
  const [mlResults, setMlResults] = useState(null)
  const [loading, setLoading] = useState(true);
  const [mood, setMood] = useState()
  const [anxiety, setAnxiety] = useState()
  const [stress, setStress] = useState()
  const [motivation, setMotivation] = useState()
  //const resultsProfilerResultsId = useSelector(state => state.resultsProfiler.resultsId)

  const disabled =
    checkIn.summary.mood === null || checkIn.summary.motivation === null || checkIn.summary.stress === null || checkIn.summary.anxiety === null;

  useEffect(() => {
    let unsubscribe = () => {}
    const run = async () => {
      if(!user) {
        return setUser(await signIn()) 
      }

      unsubscribe = mlResultSubscribe(resultId, (results) => {
        if(results.metadata?.mlResults) {
          setMlResults(results);
          setLoading(false)
          /* disabled profiler, TODO add state.resultsProfiler
          if(resultId === resultsProfilerResultsId && !results.metadata.profiler.webResults) {
            const webResults = (new Date()).getTime() - resultsProfilerStartTime
            mlResultUpdate(resultId, { profiler: { ...results.metadata.profiler, webResults } })
            dispatch(setResultsId(false))
            dispatch(setStartTime(false))
          }
          */
        }

        if(results.metadata.error) {
          setLoading(false)
        }
      });
    }

    run()

    return unsubscribe;
  //}, [resultId, dispatch, resultsProfilerResultsId, resultsProfilerStartTime, user]);
  }, [resultId, user]);

  useEffect(() => {
    if(mlResults?.metadata?.mlResults) {
      const mood = parseInt(mlResults.metadata.mlResults.mood) || 0
      const anxiety = Math.floor( parseInt(mlResults.metadata.mlResults.anxiety) ) || 0
      const stress = Math.round( anxiety / 9 * 4 )
      const motivation = Math.round( (mood + (4 - (anxiety / 9 * 4 ) ) ) / 2 )
      setMood(mood)
      setAnxiety(anxiety)
      setStress(stress)
      setMotivation(motivation)
    }
  }, [mlResults])

  const next = () => {
    save(checkIn);
    navigate(`/${inviteId}/email`)
  }

  return (
    <Container pt="124px">
      {loading &&
        <Center>
          <Spinner
            thickness='4px'
            speed='2s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      }
      {!loading &&
        <>
          <Heading
            mb="30px"
            fontSize="23px"
            fontFamily="Poppins"
            color="#000000"
            lineHeight="36px"
            textAlign="center"
          >
            Your results
          </Heading>
          <Text
            mb="60px"
            fontFamily="Poppins"
            color="#000000"
            lineHeight="36px"
            textAlign="center"
          >
            Feel free to change them here
          </Text>
          <Rating 
            name="mood" 
            icons={moodIcons} 
            initialValue={mood}
            />
          <Rating
            name="motivation"
            icons={motivationIcons}
            initialValue={motivation}
          />
          <Rating
            name="stress"
            icons={stressIcons}
            initialValue={stress}
            />
          <Rating
          name="anxiety"
          icons={anxietyIcons}
          initialValue={Math.floor(anxiety / 9 * 4)}
          />

          <Box marginBottom="20px">
            <Button disabled={disabled} text="Submit" onClick={next} />
          </Box>
        </>
      }

      {process.env.REACT_APP_TYPE === 'staging' &&
        <div style={{ marginTop: '40px' }}>
          <h4>This debug is only visilbe on staging</h4>
          <pre style={{ marginTop: '20px' }}>{ JSON.stringify(mlResults?.metadata, null, 2) }</pre>
        </div>
      }
    </Container>
  );
};

export default SummaryComponent;
