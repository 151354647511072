import {
  Box, Heading, Text
} from '@chakra-ui/react';
import './hero.scss'
import backgroundImage from '../../../assets/teamwork-2021-09-01-10-50-55-utc.jpg'

const HeroComponent = () => {
  return (
    <Box className="hero"
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      <Heading
        textAlign="center"
        fontSize="42"
        lineHeight="45px"
        fontFamily="'Poppins'"
        fontWeight="700"
        pb="40px"
      >
        Professional
        <br />
        Mental Readiness
        <br />
        Screening
      </Heading>

      <Heading
        textAlign="center"
        fontSize="17px"
        lineHeight="26px"
        fontFamily="'Poppins'"
        fontWeight="700"
        pb="68px"
        maxWidth="560px"
        margin="0 auto"
      >
        Earkick is the fastest and easiest way to assess mental readiness
        in your company, team, school or organization. 
        It’s 100% anonymous and free.
      </Heading>

      <Text
        pb="68px"
        maxWidth="540px"
        margin="0 auto"
        textAlign="center"
      >
        Our approach ensures that individuals cannot be identified. 
        Minimum of 5 participants needed to show stats.
      </Text>
    </Box>
  )
}

export default HeroComponent