

import mood5 from '../../../assets/rating/mood-5.svg';
import mood4 from '../../../assets/rating/mood-4.svg';
import mood3 from '../../../assets/rating/mood-3.svg';
import mood2 from '../../../assets/rating/mood-2.svg';
import mood1 from '../../../assets/rating/mood-1.svg';

import motivation5 from '../../../assets/rating/motivation-5.svg';
import motivation4 from '../../../assets/rating/motivation-4.svg';
import motivation3 from '../../../assets/rating/motivation-3.svg';
import motivation2 from '../../../assets/rating/motivation-2.svg';
import motivation1 from '../../../assets/rating/motivation-1.svg';

import stress5 from '../../../assets/rating/stress-5.svg';
import stress4 from '../../../assets/rating/stress-4.svg';
import stress3 from '../../../assets/rating/stress-3.svg';
import stress2 from '../../../assets/rating/stress-2.svg';
import stress1 from '../../../assets/rating/stress-1.svg';

import anxiety5 from '../../../assets/rating/anxiety-5.svg';
import anxiety4 from '../../../assets/rating/anxiety-4.svg';
import anxiety3 from '../../../assets/rating/anxiety-3.svg';
import anxiety2 from '../../../assets/rating/anxiety-2.svg';
import anxiety1 from '../../../assets/rating/anxiety-1.svg';

const moodIcons = [mood1, mood2, mood3, mood4, mood5]
const motivationIcons = [
  motivation1,
  motivation2,
  motivation3,
  motivation4,
  motivation5,
]

const stressIcons = [stress1, stress2, stress3, stress4, stress5]
const anxietyIcons = [anxiety1, anxiety2, anxiety3, anxiety4, anxiety5]

export {
  moodIcons,
  motivationIcons,
  stressIcons,
  anxietyIcons
}