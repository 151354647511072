import { Textarea, Center } from '@chakra-ui/react';

const C = ({ onChange, defaultValue }) => (
  <Center p={0} mb="42px">
    <Textarea
      border="2px"
      borderColor="#C8C8C8"
      backgroundColor="#E0E0E0"
      borderRadius="15"
      placeholder="Your answer..."
      onChange={onChange}
      height={60}
      fontWeight={700}
      defaultValue={defaultValue}
      color="#708196"
      fontFamily="Poppins"
      maxWidth="335px"
      fontSize="15px"
      lineHeight="22px"
    />
  </Center>
);

export default C;
