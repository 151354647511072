import { Container, Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Progress = ({ link, progress, hideProgress }) => (
  <Container
    paddingTop="50px"
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="start"
    maxWidth="390px"
    paddingBottom="30px"
    pr="9px"
  >
    <Link to={link}>
      <Box display="flex" alignItems="center">
        <Text
          color="#C4C4C4"
          lineHeight="26px"
          fontSize="17px"
          marginLeft="5px"
          fontWeight={700}
        >
          &lt; BACK
        </Text>
      </Box>
    </Link>

    {hideProgress ? null : (
      <Box
        background="#DCDCDC"
        borderRadius="9px"
        height="18px"
        width="260px"
        marginLeft="26px"
      >
        <Box
          id="indicator"
          background="#5F55C3"
          borderRadius="9px"
          height="18px"
          width={`${progress * 100}%`}
          transition="0.3s ease all"
          position="relative"
          sx={{
            '::after': {
              content: `''`,
              top: '4px',
              left: '5.44px',

              position: 'absolute',
              width: 'calc(100% - 12px)',
              height: '5px',
              borderRadius: '9px',
              background: '#A6A1DE',
            },
          }}
        />
      </Box>
    )}
  </Container>
);

export default Progress;
