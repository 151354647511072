import {
  Container,
  FormLabel,
  Input as InputWrapper,
  Text,
} from '@chakra-ui/react';

const Input = ({ error, label, placeholder, type, value, ...rest }) => {
  return (
    <Container width={{ base: '100%', sm: '388px' }}>
      {label && (
        <FormLabel
          fontFamily="Poppins"
          fontWeight="400"
          fontSize="14px"
          lineHeight="21px"
          color="#000000"
          marginLeft="10px"
          marginBottom="5px"
          marginRight={0}
        >
          {label}
        </FormLabel>
      )}
      <InputWrapper
        background="#ECECEC"
        border="3px solid #C8C8C8"
        borderRadius="15px"
        placeholder={placeholder}
        isRequired={true}
        minHeight="70px"
        fontFamily="Poppins"
        fontSize="20px"
        fontWeight="700"
        lineHeight="30px"
        className="input"
        sx={{ '::placeholder ': { color: '#C8C8C8' } }}
        focusBorderColor="#5F55C3"
        paddingLeft="30px"
        paddingRight="30px"
        error={error}
        type={type}
        isInvalid={error}
        value={value}
        {...rest}
      />
      {error && (
        <Text color="red" fontSize="14px" marginLeft="10px" marginTop="4px">
          {error}
        </Text>
      )}
    </Container>
  );
};

export default Input;
