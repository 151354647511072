import {
  Box, 
  Button,
  Center,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spacer,
  Text
} from '@chakra-ui/react'
import './delete-modal.scss'

const DeleteModal = ({ isOpen, onClose, onDelete, icon, subject }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="delete-modal">
        <ModalBody display="block">
          <Center>
            <Box
              className="icon"
              style={{
                width: '64px',
                height: '64px',
                background: `#F3F2E9 url(${icon}) center center no-repeat`,
                border: '6px solid #F3F2E9',
                borderRadius: '50px',
                marginBottom: '15px'
              }}
              aria-label='Play'
              size='lg'
            ></Box>
          </Center>
          <Center>
            <Heading>Confirmation</Heading>
          </Center>
          <Center>
            <Text>Are you sure you want to delete this {subject} memo?</Text>
          </Center>
        </ModalBody>

        <ModalFooter display="block" className="delete-modal">
          <Center>
          <Box>
            <Button onClick={onClose} >
              Cancel
            </Button>
          </Box>
          <Spacer />
          <Box>
            <Button 
              style={{
                color: '#FF453A'
              }}
              onClick={onDelete}>Delete</Button>
          </Box>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal