import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Center,
  Image,
  Text,
  SkeletonText,
  Box,
} from '@chakra-ui/react';
import group497 from '../../assets/group-497.svg';
import getCheckInGroup from './get-check-in-group';
import Button from '../check-in/components/button';

const CheckInIndex = () => {
  const [isLoading, setLoading] = useState(true);
  const { inviteId } = useParams();
  const checkInGroup = useSelector(state => state.checkInGroup.data);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await getCheckInGroup(inviteId, checkInGroup, dispatch);
      setLoading(false);
    })();
  }, [inviteId, checkInGroup, dispatch]);

  return (
    <Center
      display="flex"
      flexDirection="column"
      height="100vh"
      paddingTop="20px"
    >
      <Box
        alignItems="flex-end"
        mb="21px"
      >
        <Image height="41px" src={group497} />
      </Box>
    
      <Box width="100%">
        <SkeletonText mt="4" noOfLines={4} spacing="4" isLoaded={!isLoading}>
          <Text
            color="#444444"
            textAlign="center"
            lineHeight="45px"
            fontSize="30px"
            mb="65px"
          >
            <span style={{
              fontWeight: 700
            }}>{checkInGroup.name}</span> invites you!
          </Text>

          <Text
            fontSize="24px"
            lineHeight="36px"
            textAlign="center"
            color="#444444"
            fontWeight={700}
          >
            {checkInGroup.groupName}
          </Text>
          <Text
            fontSize="24px"
            lineHeight="36px"
            textAlign="center"
            color="#444444"
          >
            Mental Readiness
          </Text>
          <Text
            marginBottom="82px"
            fontSize="24px"
            lineHeight="36px"
            textAlign="center"
            color="#444444"
          >
            Screening
          </Text>

          <Button
            width={263}
            minWidth="263px"
            link={`/${inviteId}/start`}
            text="Continue"
          />
        </SkeletonText>
      </Box>

    </Center>
  );
};

export default CheckInIndex;
