const questions = {
  feeling: {
    header: 'How are you today?',
    text: 'Describe in a few sentences how you are feeling. Happy, down, valued, motivated, etc.',
  },
  anxious: {
    header: 'Have you been anxious about anything?',
    text: 'future, family, social circle, finances, health, school etc..',
  },
  stress: {
    header: 'How’s your stress level?',
    text: 'Affecting productivity, balanced, overwhelming….?',
  },
  symptoms: {
    header: 'Have you had any symptoms?',
    text: 'Pain, sweating, tense muscles, racing heart, difficulty breathing or other annoyances more than usual?',
  },
  else: {
    header: 'Anything else you want to share?',
    text: 'Achievements, venting, grateful thoughts…?',
  },
};

export default questions;
