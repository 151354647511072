import { groupCheckInGet } from '../../api/functions'
import { set } from '../../store/check-in-group'

const loadInvite = async (inviteId) => {
  const response = await groupCheckInGet({ inviteId })

  return response.data
}

const getCheckInGroup = async (inviteId, checkInGroup, dispatch) => {
  if(checkInGroup.id !== inviteId) {
    const data = await loadInvite(inviteId)
    
    await dispatch(set({id: inviteId, ...data}))
  }

  return checkInGroup
}

export default getCheckInGroup