import Button from '../../check-in/components/button';
import { Box } from '@chakra-ui/react';
import './share-invite.scss'

const ShareInvite = ({ onOpen, classPosition }) => {
  return (
    <Box
      className={`share-invite ${classPosition}`}
    >
      <Button
        small
        minWidth="167px"
        width="167px"
        onClick={() => onOpen(true)}
        text="Share Invite"
      />
    </Box>
  )
}

export default ShareInvite