import { v4 as uuid } from 'uuid'
import { useParams } from 'react-router-dom';
import { Container, Center, Heading, Box, Spinner, Text } from '@chakra-ui/react';
import InviteModal from '../invite-modal';
import { useState, useEffect } from 'react';
import { groupCheckInSummary } from '../../../api/functions';
import { useSelector } from 'react-redux';
import Chart from './chart';
import RadialProgressBar from './radial-progress-bar';
import MinimumMessage from './miniumum-message'
import {
  moodEmojis,
  motivationEmojis,
  stressEmojis,
  anxietyEmojis,
} from './emoji-data';
import Benchmark from './benchmark';
import PointerProgress from './pointerProgress';
import ShareInvite from './share-invite'

const emptySummaryElement = {
  total: 0,
  participants: 0,
  summary: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
};

const emptySummary = {
  participants: 0,
  mood: Object.assign({}, emptySummaryElement),
  anxiety: Object.assign({}, emptySummaryElement),
  stress: Object.assign({}, emptySummaryElement),
  motivation: Object.assign({}, emptySummaryElement),
};

const DashboardSummary = () => {
  const [isOpen, onOpen] = useState(false);
  const [summary, setSummary] = useState(emptySummary);
  const [summaryKey, setSummaryKey] = useState(uuid());
  const [loading, setLoading] = useState(true)

  const { inviteId } = useParams();
  const accessToken = useSelector(state => state.dashboard.accessToken);

  useEffect(() => {
    const getSummary = async () => {
      const { data } = await groupCheckInSummary({ inviteId, accessToken });
      setLoading(false)
      if(data.summary) {
        setSummary(data.summary);
        setSummaryKey(JSON.stringify(data.summary))
      }
    }
    const interval = setInterval(getSummary, 10000);
    getSummary()

    return () => clearInterval(interval)
  }, [inviteId, accessToken]);

  const mood = Object.values(summary.mood.summary);
  const motivation = Object.values(summary.motivation.summary);
  const stress = Object.values(summary.stress.summary);
  const anxiety = Object.values(summary.anxiety.summary);

  return (
    <>
      {summary.participants >= 5 && (
        <ShareInvite onOpen={onOpen} classPosition="desktop"></ShareInvite>
      )}
      <Container pl={0} pr={0} maxWidth="1440px" position="relative" key={summaryKey}>
        {summary.participants >= 5 && (
          <ShareInvite onOpen={onOpen} classPosition="mobile"></ShareInvite>
        )}


      {loading &&
        <Center> 
          <Spinner 
            thickness='4px' 
            speed='2s' 
            emptyColor='gray.200' 
            color='blue.500' 
            size='xl' 
          /> 
      </Center> 
      }
      {!loading &&
        <>
      
          {summary.error || summary.participants < 5 ? (
            <MinimumMessage summary={summary} onOpen={onOpen} />
          ) : (
            <Center display="flex" flexDirection="column">

              <Text
                fontSize="17px"
                mt="40px"
                lineHeight="26px"
                color="#000000"
                fontWeight={700}
                mb="87px"
                fontFamily="Poppins"
              >
                Participants: {summary.participants}
              </Text>

              <Heading
                mb="34px"
                fontFamily="Poppins"
                fontWeight="700"
                fontSize="17px"
                lineHeight="26px"
                color="#999999"
              >
                Overview
              </Heading>

              <Box mb="44px">
                <RadialProgressBar progress={Math.round(summary.readiness)} />
              </Box>

              <Center display="flex" flexDirection="column" mb="100px">
                <Box mb="9px">
                  <Benchmark percentage={Math.round(summary.finalMood)} type="Mood" />
                </Box>
                <Benchmark percentage={Math.round(summary.finalMotivation)} type="Motivation" />
              </Center>

              <Center marginBottom="143px">
                <Box marginRight="30px">
                  <PointerProgress type="Stress" percentage={summary.finalStress} />
                </Box>
                <PointerProgress type="Anxiety" percentage={summary.finalAnxiety} />
              </Center>

              {mood && (
                <Box pb="140px">
                  <Chart
                    data={mood}
                    emojis={moodEmojis}
                    title="Mood"
                    color="#5F55C3"
                  />
                </Box>
              )}
              {motivation && (
                <Box pb="140px">
                  <Chart
                    data={motivation}
                    emojis={motivationEmojis}
                    title="Motivation"
                    color="#5BC22B"
                  />
                </Box>
              )}

              {stress && (
                <Box pb="140px">
                  <Chart
                    data={stress}
                    emojis={stressEmojis}
                    title="Stress"
                    color="#FFA901"
                  />
                </Box>
              )}

              {anxiety && (
                <Box pb="140px">
                  <Chart
                    data={anxiety}
                    emojis={anxietyEmojis}
                    title="Anxiety"
                    color="#C22BB3"
                  />
                </Box>
              )}
            </Center>
          )}

        </>
      }
      <InviteModal
        inviteId={inviteId}
        isOpen={isOpen}
        onClose={() => onOpen(false)}
      />
    </Container>
    </>
  );
};

export default DashboardSummary;