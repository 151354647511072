import {
  Box,
  Container,
  Heading,
  Center,
} from '@chakra-ui/react';
import './index.scss'
import Features from './features'
import StartButton from './start-button'
import Hero from './hero'
import BigLogos from './big-logos'

const StartingScreen = () => (
  <Container pl={0} pr={0} className="dashboard-home">
    <Hero />
    <StartButton></StartButton>
    <BigLogos></BigLogos>
  
    <Features></Features>

    <Box className="video-section">
      <Center>
        <iframe
          width="300"
          height="200"
          src="https://www.youtube.com/embed/aYIIb5VhTaI"
          title="Professional Mental Readiness Screening for Orgs - Earkick"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          ></iframe>
        <Box className="right-box">
          <Heading>Screening company mental readiness has never been easier</Heading>
        </Box>
      </Center>
      <Box className="bottom-box">
        <Heading>Screening company mental readiness has never been easier</Heading>
      </Box>
    </Box>

    <StartButton></StartButton>

  </Container>
);

export default StartingScreen;
