import { useParams } from 'react-router-dom';
import { Center, Text } from '@chakra-ui/react';

import React from 'react';

import Button from '../check-in/components/button';

const CheckInStart = () => {
  const { inviteId } = useParams();

  return (
    <Center
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="flex-start"
      paddingTop="50px"
    >
      <Text
        fontSize="24px"
        lineHeight="36px"
        textAlign="center"
        color="#444444"
        fontWeight={700}
        marginTop="16px"
        marginBottom="46px"
      >
        100% Anonymous
      </Text>
      <Text
        fontSize="24px"
        lineHeight="36px"
        textAlign="center"
        color="#444444"
        fontWeight={400}
        marginBottom="50px"
        maxWidth="350px"
        >
        Your data is automatically processed and will not be shared with anyone.
      </Text>

      <Button
        height="63px"
        width="263px"
        link={`/${inviteId}/typing`}
        marginBottom="20px"
        text="Text Survey"
        backgroundColor="#fff"
        color="#000"
        borderColor="#ccc"
        hoverBackgroundColor="#777"
      />

      <Button
        marginTop="20px"
        height="63px"
        width="263px"
        link={`/${inviteId}/voice`}
        marginBottom="20px"
        text="Voice Survey"
        backgroundColor="#fff"
        color="#000"
        borderColor="#ccc"
        hoverBackgroundColor="#777"
      />

      <Button
        height="63px"
        width="263px"
        link={`/${inviteId}/video`}
        text="Video Survey"
        backgroundColor="#fff"
        color="#000"
        borderColor="#ccc"
        hoverBackgroundColor="#777"
      />

    <Text 
      color="#444"
      marginTop="50px"
      fontSize="12px"
    >By continuing, you agree to our <a href="https://earkick.com/privacy-policy" _target="blank">Privacy Policy</a></Text>
    </Center>
  );
};

export default CheckInStart;
