import { Route, Routes } from 'react-router-dom'
import Typing1 from '../../containers/check-in/typing'


const CheckInTypingRoutes = () => (
  <Routes>
    <Route
      path=''
      element={(
        <Typing1 />
      )}
    />
  </Routes>
)

export default CheckInTypingRoutes