import { v4 as uuid } from 'uuid';
import React, { memo, useEffect } from 'react';
import {
  ChakraProvider,
  Container,
  theme,
  useMediaQuery,
} from '@chakra-ui/react';
import { Outlet, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../store/check-in';

const CheckInLayout = memo(() => {
  const dispatch = useDispatch();
  const checkIn = useSelector(state => state.checkIn.data);
  const { inviteId } = useParams();

  const [isSmallerThan860] = useMediaQuery('(max-width: 860px)');

  useEffect(() => {
    (async () => {
      if (!checkIn.id) {
        dispatch(
          setValue({
            key: 'id',
            value: uuid(),
          })
        );
      }
    })();
  }, [checkIn, dispatch]);

  useEffect(() => {
    (async () => {
      if (inviteId !== checkIn.inviteId) {
        dispatch(
          setValue({
            key: 'inviteId',
            value: inviteId,
          })
        );
      }
    })();
  }, [checkIn, inviteId, dispatch]);

  return (
    <ChakraProvider theme={theme}>
      <Container
        maxWidth={1440}
        minHeight="100vh"
        pl={isSmallerThan860 ? '20px' : '50px'}
        pr={isSmallerThan860 ? '20px' : '50px'}
      >
        <Outlet />
      </Container>
    </ChakraProvider>
  );
});

CheckInLayout.displayName = 'CheckInLayout';

export default CheckInLayout;
