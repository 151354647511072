import { v4 as uuid } from 'uuid'
import { storage, signIn } from './firebase';
import { ref, uploadBytes } from 'firebase/storage';

const submitFile = async ({ url }) => {
  const { user } = await signIn()
  const id = uuid()
  const storageRef = ref(storage, `public-upload/demo/audio/${id}`);
  const file = await fetch(url).then(r => r.blob());
  await uploadBytes(storageRef, file, {
    contentType: 'audio/wav',
    customMetadata: {
      user: user.uid,
      sampleRate: 48000
    }
  })
    
  return id
}

export {
  submitFile
}