import { createSlice } from '@reduxjs/toolkit'

export const checkInGroup = createSlice({
  name: 'checkInGroup',
  initialState: {
    data: {
      empty: true
    },
  },
  reducers: {
    set: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { set } = checkInGroup.actions

export default checkInGroup.reducer