import React, { memo, useEffect } from 'react';
import {
  ChakraProvider,
  Container,
  useMediaQuery,
  theme,
} from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router';
import { signIn } from '../../api/firebase';
import Footer from './footer/footer';
import Header from './header/header';

const CheckInLayout = memo(() => {
  const location = useLocation()
  const [isSmallerThan860] = useMediaQuery('(max-width: 860px)');
  const fullHeight = isSmallerThan860
    ? 'calc(100vh - 249px)'
    : 'calc(100vh - 201px)';

  useEffect(() => {
    const getUser = async () => {
      await signIn();
    };

    getUser();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Header />
      {location.pathname === '/dashboard' &&
        <>
          <Outlet />
        </>
      }
      {location.pathname !== '/dashboard' &&
        <Container
          minHeight={fullHeight}
          pl={isSmallerThan860 ? '20px' : '0'}
          pr={isSmallerThan860 ? '20px' : '0'}
        >
          <Outlet />
        </Container>
      }
      <Footer />
    </ChakraProvider>
  );
});

CheckInLayout.displayName = 'CheckInLayout';

export default CheckInLayout;
