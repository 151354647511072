import { Box, Center } from '@chakra-ui/react';
import Button from '../../check-in/components/button';

const StartButtonComponent = () => {
  return (<Box pt={{ base: 33, sm: 50 }} className="start-button">
    <Center>
      <Button link="/dashboard/form" text="Start" />
    </Center>
  </Box>)
}

export default StartButtonComponent