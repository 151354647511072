import { Heading, Text, Container } from '@chakra-ui/react';

const C = ({ header, text }) => (
  <Container
    border="3px solid #C8C8C8"
    borderRadius="15px"
    maxWidth="335px"
    pt="24px"
    pr="9px"
    pl="9px"
    pb="20px"
    mb="20px"
  >
    <Heading
      fontFamily={'Poppins'}
      fontSize="20px"
      lineHeight="30px"
      textAlign="center"
      marginBottom="15px"
    >
      {header}
    </Heading>
    <Text
      fontWeight={400}
      fontSize="15px"
      lineHeight="22px"
      textAlign="center"
      color="#000000"
    >
      {text}
    </Text>
  </Container>
);

export default C;
