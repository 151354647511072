import { createSlice } from '@reduxjs/toolkit'

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    accessToken: '',
    inviteId: ''
  },
  reducers: {
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload
    },
    setInviteId: (state, { payload }) => {
      state.inviteId = payload
    },
  },
})

export const { setAccessToken, setInviteId } = dashboard.actions

export default dashboard.reducer