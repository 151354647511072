import { Box, Container, Text, Image } from '@chakra-ui/react';
//import benchmarkSuccess from '../../../assets/benchmark-success.svg';
//import benchmarkWarning from '../../../assets/benchmark-warning.svg';
import benchmarkPercentage from '../../../assets/benchmark-percentage.svg';

const Benchmark = ({ type, percentage, benchmark }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexDirection="row"
      width="100%"
      maxWidth="100%"
      justifyContent="center"
    >
        <Container width="240px" minWidth="240px" padding={0} margin={0}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb="5px"
            position="relative"
            >
            {benchmark &&
              <Box
                position="absolute"
                left={`${benchmark}%`}
                bottom="-4px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginLeft="-17px"
              >
                <Text fontSize="6px" color="black" fontWeight="700">
                  Benchmark
                </Text>

                <Image
                  width="11px"
                  src={benchmarkPercentage}
                  alt="Benchmark status"
                  />
              </Box>
            }

            <Text fontWeight="700" fontSize="12px" lineHeight="18px">
              {type}
            </Text>

            <Text
              fontWeight="700"
              fontSize="12px"
              lineHeight="18px"
              color="#999999"
              >
              {percentage}%
            </Text>
          </Box>

        <Box background="#DCDCDC" borderRadius="9px" height="18px" width="100%">
          <Box
            id="indicator"
            background="#5F55C3"
            borderRadius="9px"
            height="18px"
            width={`${percentage}%`}
            position="relative"
            sx={{
              '::after': {
                content: `''`,
                top: '4px',
                left: '5.44px',

                position: 'absolute',
                width: 'calc(100% - 12px)',
                height: '5px',
                borderRadius: '9px',
                background: '#A6A1DE',
              },
            }}
          />
        </Box>
      </Container>

      { /*
        <Container padding={0} pl="5px">
          <Image
            alt="success"
            src={percentage <= benchmark ? benchmarkWarning : benchmarkSuccess}
            />
        </Container>
          */ }
    </Box>
  );
};

export default Benchmark;
