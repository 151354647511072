import {
  Container,
  Stack,
  Image,
} from '@chakra-ui/react';
import google from '../../../assets/google-logo-doodle-grey.svg';
import microsoft from '../../../assets/microsoft_grayscale-01.svg';
import pg from '../../../assets/procter-gamble-logo-black-and-white.svg';
import './big-logos.scss'

const BigLogos = () => {
  return (
    <Container
      className="big-logos"
      justify={'center'}
      align={'center'}
      pt={{ base: 30, sm: 50 }}
      pb={{ base: 30, sm: 50 }}
    >
      <Stack
        direction={'row'}
        className="logo__wrapper"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Image width={171} src={google} />
        <Image width={127} src={microsoft} style={{ marginLeft: '15px' }} />
        <Image className="image-pg" width={66} src={pg} />
      </Stack>
    </Container>
  )
}

export default BigLogos