import { Box, Button as ChakraButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Button = ({
  link,
  onClick,
  text,
  type,
  isLoading,
  name,
  small,
  minHeight,
  minWidth,
  marginBottom,
  width,
  height,
  secondText,
  disabled,
  color,
  backgroundColor,
  borderColor,
  hoverBackgroundColor,
  textTransform
}) => {
  const ButtonContainer = () => {
    return (
      <ChakraButton
        className="button-wrapper"
        backgroundColor={backgroundColor || "#5F55C3"}
        _hover={{ background: hoverBackgroundColor || 'rgba(95, 85, 195, 0.9)', color: '#fff' }}
        _active={{
          background: !disabled && 'rgba(95, 85, 195, 1)',
          boxShadow: !disabled && `0px 7px ${(borderColor || '#130c56')}`,
        }}
        height={height}
        minWidth={minWidth || { base: '100%', sm: '354px' }}
        width={width}
        marginBottom={marginBottom || ''}
        borderRadius="15px"
        boxShadow={`0px 7px ${(borderColor || '#312a77')}`}
        border={borderColor && `2px solid ${borderColor}`}
        minHeight={minHeight || small ? '33px' : '64px'}
        fontFamily="Poppins"
        fontSize="17px"
        color={color || "#FFFFFF"}
        textTransform={textTransform || "uppercase"}
        fontWeight="700"
        type={type || 'submit'}
        isLoading={isLoading}
        onClick={onClick}
        name={name}
        display="flex"
        disabled={disabled}
        flexDirection="column"
      >
        {text || 'Next '}
        {secondText && (
          <span style={{ padding: 0, margin: 0, textTransform: 'lowercase' }}>
            {secondText}
          </span>
        )}
      </ChakraButton>
    );
  };

  return link ? (
    <Link
      to={link}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <ButtonContainer />
    </Link>
  ) : (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <ButtonContainer />
    </Box>
  );
};

export default Button;
