
import { Center, Heading, Box, Text } from '@chakra-ui/react';
import Button from '../../check-in/components/button';

const MinimumMessage = ({ summary, onOpen }) => {
  return (
    <Center pt={{ base: 33, sm: 133 }} display="flex" flexDirection="column">
      {summary.error &&
        <Heading
          color="#999999"
          lineHeight="26px"
          fontSize="17px"
          fontWeight="700"
          mb="36px"
        >
          Error loading summary :( 
        </Heading>
      }
      {!summary.error &&
        <>
          <Heading
            color="#999999"
            lineHeight="26px"
            fontSize="17px"
            fontWeight="700"
            mb="36px"
          >
            Participants: {summary.participants}
          </Heading>

          <Box
            width="356px"
            height="331.26px"
            border="3px solid #C8C8C8"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Text
              marginTop="65px"
              fontWeight={700}
              fontSize="20px"
              lineHeight="30px"
              color="#000000"
              textAlign="center"
              marginBottom="40px"
            >
              Minimum of 5 participants needed to show stats
            </Text>
            <Button
              small
              minWidth="167px"
              width="167px"
              onClick={() => onOpen(true)}
              text="Share Invite"
            />
          </Box>
        </>
      }
    </Center>
  );
};

export default MinimumMessage