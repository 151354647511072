import { Route, Routes } from 'react-router-dom'
import VideoStart from '../../containers/check-in/video'

const CheckInVideoRoutes = () => (
  <Routes>
    <Route
      path=''
      element={(
        <VideoStart />
      )}
    />
  </Routes>
)

export default CheckInVideoRoutes