import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Box, Center, Heading, VStack, Container } from '@chakra-ui/react';
import { groupCheckInCreate } from '../../api/functions';
import Input from '../check-in/components/input';
import Button from '../check-in/components/button';
import * as Yup from 'yup';
import { setAccessToken, setInviteId } from '../../store/dashboard';

/* Defining the validation schema for the form. */
const formSchema = Yup.object().shape({
  name: Yup.string().required('Your name is a required field'),
  groupName: Yup.string().required('Group name is a required field'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is a required field'),
});

const processFormSubmit = async values => {
  const response = await groupCheckInCreate(values);
  return { 
    accessToken: response.data.accessToken,
    inviteId: response.data.inviteId
  };
};

const initialValues = { name: '', groupName: '', email: '' };

const DashboardForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Container pl={0} pr={0}>
      <Box pt={{ base: 33, sm: 133 }} pb={{ base: 33, sm: 133 }}>
        <Heading
          textAlign="center"
          fontSize="30px"
          lineHeight="45px"
          fontFamily="'Poppins'"
          fontWeight="700"
          color="#444444"
          pb="32px"
        >
          Create Screening
        </Heading>

        <Formik
          validationSchema={formSchema}
          initialValues={initialValues}
          onSubmit={async values => {
            const { inviteId, accessToken } = await processFormSubmit(values);
            await dispatch(
              setAccessToken(accessToken)
            );
            await dispatch(
              setInviteId(inviteId)
            );
            navigate(`/dashboard/${inviteId}`, { replace: true });
          }}
        >
          {props => (
            <Form noValidate>
              <VStack spacing={6}>
                <Field name="name">
                  {({ form }) => {
                    return (
                      <Input
                        name="name"
                        placeholder="Name"
                        label={
                          <span>
                            Your<strong> Name</strong> - will be shown to
                            participants
                          </span>
                        }
                        isRequired={true}
                        type="text"
                        error={form.submitCount > 0 ? form.errors.name : ''}
                        value={form.values.name}
                        onChange={e =>
                          form.setFieldValue('name', e.target.value)
                        }
                      />
                    );
                  }}
                </Field>

                <Field name="groupName">
                  {({ form }) => {
                    return (
                      <Input
                        name="groupName"
                        placeholder="Group Name"
                        label={
                          <span>
                            <strong>Group Name</strong> - e.g. company name,
                            class name
                          </span>
                        }
                        isRequired={true}
                        type="text"
                        error={
                          form.submitCount > 0 ? form.errors.groupName : ''
                        }
                        value={form.values.groupName}
                        onChange={e =>
                          form.setFieldValue('groupName', e.target.value)
                        }
                      />
                    );
                  }}
                </Field>

                <Field name="email">
                  {({ form }) => {
                    return (
                      <Input
                        name="email"
                        placeholder="Email"
                        label={
                          <span>
                            Your <strong>Email</strong> - to get notified. Will
                            not be shared'
                          </span>
                        }
                        isRequired={true}
                        type="email"
                        error={form.submitCount > 0 ? form.errors.email : ''}
                        value={form.values.email}
                        onChange={e =>
                          form.setFieldValue('email', e.target.value)
                        }
                      />
                    );
                  }}
                </Field>

                <Center pt="40px" width="100%">
                  <Button
                    type="submit"
                    text="Create Check-in"
                    isLoading={props.isSubmitting}
                  />
                </Center>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default DashboardForm;
