import { ref, uploadBytes } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Box, Image } from '@chakra-ui/react';
import { storage } from '../../../api/firebase';
import sunglasses from '../../../assets/sunglasses.svg';
import PlayIcon from '../../../assets/play-icon.png'
import DeleteIcon from '../../../assets/Delete.svg'
import { milisToTime } from '../helpers';
import { submitFile } from '../../../api/submit-file'
import convert from '../helpers/video-to-audio'
import save from '../save';

const Video = ({ 
  command, 
  id, 
  onDelete,
  setTimerInterval, 
  setVideoLength, 
  timerInterval, 
  showPlayer, 
  videoLength, 
  videoUploaded,
  mlResultIdSet
}) => {
  const navigate = useNavigate()
  const videoRef = useRef()
  const { inviteId } = useParams();
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [mediaUrl, setMediaUrl] = React.useState(null)
  const [startTimer, setStartTimer] = useState(new Date())
  const [playing, setPlaying] = useState(false)
  const [file, setFile] = useState()
  const checkIn = useSelector(state => state.checkIn.data);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = React.useCallback(async () => {
    try {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener(
        'dataavailable',
        handleDataAvailable
      );
    } catch (e) {
      console.log(e)
      //TODO alert
    }

    mediaRecorderRef.current?.start();
  }, [mediaRecorderRef, handleDataAvailable, webcamRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current?.stop();
    // setVideo()
  }, [mediaRecorderRef]);

  const upload = async () => {
    console.log('uploading')
    const storageRef = ref(storage, `public-upload/${id}`);

    // 'file' comes from the Blob or File API
    const snapshot = await uploadBytes(storageRef, file)
    await videoUploaded(snapshot);

    //setRecordedChunks([]);
    let convertedAudioDataObj = await convert(file, 'webm', 'codecs=opus');
    
    const resultId = await submitFile({ url: convertedAudioDataObj?.data })
    await mlResultIdSet(resultId)
    await save({ ...checkIn, mlResultId: resultId })

    navigate(`/${inviteId}/summary/${resultId}`)
  }

  const handleUpload = React.useCallback(async () => {
    console.log('handleUpload()', recordedChunks.length)
    if (recordedChunks.length) {
      const mediaFile = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      
      setMediaUrl(URL.createObjectURL(mediaFile))
      setFile(mediaFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  useEffect(() => {
    console.log(`Video recorder received command ${command}`)
    if (command === 'start') {
      handleStartCaptureClick();
      setStartTimer(new Date())
      const intervalId = setInterval(() => {
        const startTime = startTimer.getTime()
        const timeNow = new Date()
        setVideoLength(milisToTime(timeNow.getTime() - startTime))
      }, 100)
      setTimerInterval(intervalId)
    } else if (command === 'stop') {
      clearInterval(timerInterval)
      handleStopCaptureClick();
    } else if (command === 'preview') {
      clearInterval(timerInterval)
      handleUpload();
    } else if(command === 'upload') {
      console.log('command what? '+ command)
      upload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      if(timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [command]);

  const handleStart = x => {};

  const handleDrag = x => {};

  const handleStop = x => {};
  
  return (
    <Box className="video-container" style={{ position: 'relative'}}>
        <Box style={{
          visibility: showPlayer ? 'hidden' : 'visible',
          height: showPlayer ? '0px' : '100%'
        }}>
          <Box className="video-frame">
            <Webcam
              audio={true}
              muted={true}
              ref={webcamRef}
              mirrored={true}
              className="check-in-video"
            />
            <Box className="chopper chopper-left"></Box>
            <Box className="chopper chopper-right"></Box>
          </Box>

          <Draggable
            axis="y"
            handle=".handle"
            defaultPosition={{ x: 0, y: 120 }}
            onStart={handleStart}
            onDrag={handleDrag}
            onStop={handleStop}
            bounds="parent"
            >
            <Box className="handle">
              <Image
                pointerEvents="none"
                className="video-sunglasses"
                src={sunglasses}
                />
            </Box>
          </Draggable>
        </Box>
      {showPlayer && mediaUrl &&
        <Box style={{
          visibility: !showPlayer ? 'hidden' : 'visible',
        }}>
          <video
            onPause={() => {
              setPlaying(false)
            }}
            onEnded={() => {
              setPlaying(false)
            }}
            ref={videoRef}
            controls
            style={{
              width: '355px',
              height: '355px',
              objectFit: 'cover',
              cursor: 'pointer',
              posiiton: 'absolute',
              filter: playing ? '' : 'blur(4px)',
              transform: 'scaleX(-1)'
            }}>
            <source src={mediaUrl} type="video/webm;codecs=vp8" />
            Your browser does not support the video tag.
          </video>
          {!playing &&
            <>
              <Box
                style={{
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  left: '58px',
                  width: '70px',
                  height: '70px',
                  background: `#000 url(${PlayIcon}) center center no-repeat`,
                  borderRadius: '50px',
                  cursor: 'pointer'
                }}
                aria-label='Play'
                size='lg'
                onClick={() => {
                  setPlaying(true)
                  videoRef.current.play();
                }}
              >
              </Box>
              <Box style={{
                  position: 'absolute',
                  top: 'calc(50% - 20px)',
                  left: 'calc(50% - 25px)',
                  width: '70px',
                  height: '40px',
                  fontWeight: 700,
                  fontSize: '24px',
                  color: '#000',
                  textAlign: 'center'
                }}>{videoLength}</Box>
              <Box
                style={{
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  right: '58px',
                  width: '70px',
                  height: '70px',
                  background: `transparent url(${DeleteIcon}) center center no-repeat`,
                  cursor: 'pointer'
                }}
                aria-label='Play'
                size='lg'
                onClick={onDelete}
              >
              </Box>
            </>
          }
        </Box>
      }
    </Box>
  );
};

export default Video;
