import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '../containers/dashboard/home'
import Form from '../containers/dashboard/form'
import Summary from '../containers/dashboard/summary'
import NotFound from '../containers/dashboard/not-found'

const AccountRoutes = memo(() => (
  <Routes>
    <Route
      path='/'
      element={(
        <Home />
    )}
    />      
    <Route
      path='form'
      element={(
        <Form />
      )}
    />
    <Route
      path=':inviteId/*'
      element={(
        <Summary />
      )}
    />
    <Route
      path='*'
      element={(
        <NotFound />
      )}
    />
  </Routes>
  
 ))

AccountRoutes.displayName = 'AccountRoutes'

export default AccountRoutes